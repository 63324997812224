import { createRouter, createWebHistory } from 'vue-router'
import TimeStamp from '@/views/timestamp.vue'
import StringLengthCalculator from '@/views/StringLengthCalculator.vue'
import JsonFormatter from '@/views/JsonFormatter.vue'
import UrlEncoderDecoder from '@/views/UrlEncoderDecoder.vue'

// import Index from '../views/Index.vue'

// 路由配置
const routes = [
  {
    path: '/',
    redirect: '/TimeStamp'
  },
  {
    path: '/TimeStamp',
    name: '在线时间戳转换',
    visible: true,
    component: TimeStamp
  },
  {
    path: '/JsonFormatter',
    name: 'Json格式化',
    visible: true,
    component: JsonFormatter
  },
  {
    path: '/StringLengthCalculator',
    name: '字符串长度计算',
    visible: true,
    component: StringLengthCalculator
  },
  {
    path: '/UrlEncoderDecoder',
    name: 'URL编码解码',
    visible: true,
    component: UrlEncoderDecoder
  }
]

// 创建路由实例
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
