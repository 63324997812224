<template>
  <div class="container mt-5">
    <h2 class="mb-4">在线时间戳转换工具</h2>

    <!-- 当前系统时间及时间戳 -->
    <div class="alert alert-info">
      <strong>当前系统时间：</strong> {{ currentDate }}
      <span
        class="btn btn-sm btn-secondary"
        @click="copyToClipboard(currentDate)"
        >复制</span
      >
      <br />
      <strong>当前时间戳：</strong> {{ currentTimestamp }}
      <span
        class="btn btn-sm btn-secondary"
        @click="copyToClipboard(currentTimestamp)"
        >复制</span
      >
    </div>

    <hr />

    <!-- 输入时间戳并转换为日期 -->
    <div class="form-group">
      <label for="timestampInput">时间戳转换为日期</label>
      <input
        type="number"
        id="timestampInput"
        class="form-control"
        v-model="timestamp"
        placeholder="请输入时间戳（秒）"
      />
      <button class="btn btn-primary mt-2" @click="convertToDate">
        转换为日期
      </button>
    </div>

    <div v-if="convertedDate" class="alert alert-success mt-3">
      转换结果：{{ convertedDate }}
    </div>

    <hr />

    <div class="form-group">
      <label for="dateInput">日期转换为时间戳</label>
      <input
        type="text"
        id="dateInput"
        class="form-control"
        v-model="dateString"
        placeholder="请输入日期和时间（例如：2024-11-11 12:00）"
      />
      <button class="btn btn-secondary mt-2" @click="convertToTimestamp">
        转换为时间戳
      </button>
    </div>

    <div v-if="convertedTimestamp" class="alert alert-info mt-3">
      转换结果：{{ convertedTimestamp }}
    </div>
  </div>
  <AlertMessage
    v-for="(alert, index) in alerts"
    :key="index"
    :message="alert.message"
    :timeout="alert.timeout"
    :type="alert.type"
  ></AlertMessage>
</template>

<script>
import AlertMessage from '@/components/AlertMessage.vue'
export default {
  name: 'TimeStamp',
  components: {
    AlertMessage
  },
  data() {
    return {
      timestamp: '', // 用户输入的时间戳
      dateString: '', // 用户输入的日期字符串
      convertedDate: '', // 转换得到的日期
      convertedTimestamp: '', // 转换得到的时间戳
      currentTimestamp: '', // 当前系统时间戳
      currentDate: '', // 当前系统时间对应的日期时间
      alerts: []
    }
  },
  methods: {
    convertToDate() {
      // 判断时间戳是否包含毫秒
      if (this.timestamp) {
        // 如果时间戳长度为13位，则说明是毫秒时间戳
        if (this.timestamp.toString().length === 13) {
          const date = new Date(this.timestamp) // 毫秒级时间戳直接使用
          this.convertedDate = date.toLocaleString() // 格式化日期
        } else if (this.timestamp.toString().length === 10) {
          const date = new Date(this.timestamp * 1000) // 秒级时间戳需要乘以1000
          this.convertedDate = date.toLocaleString() // 格式化日期
        } else {
          this.convertedDate = '请输入有效的时间戳'
        }
      } else {
        this.convertedDate = '请输入有效的时间戳'
      }
    },
    convertToTimestamp() {
      // 判断日期字符串是否有效
      if (this.dateString) {
        const date = new Date(this.dateString)

        // 判断是否为有效日期
        if (!isNaN(date.getTime())) {
          const dateStringLength = this.dateString.length

          // 如果日期字符串的长度包含毫秒（例如 '2022-12-31T23:59:59.999'）
          if (dateStringLength > 19 && this.dateString.includes('.')) {
            // 毫秒级时间戳
            this.convertedTimestamp = date.getTime() // 毫秒时间戳
          } else {
            // 秒级时间戳
            this.convertedTimestamp = Math.floor(date.getTime() / 1000) // 转换为秒
          }
        } else {
          this.convertedTimestamp = '请输入有效的日期'
        }
      } else {
        this.convertedTimestamp = '请输入有效的日期'
      }
    },
    updateCurrentTime() {
      // 获取当前系统时间和时间戳
      const now = new Date()
      this.currentTimestamp = Math.floor(now.getTime() / 1000) // 当前时间戳（秒）
      this.currentDate = now.toLocaleString() // 当前日期时间
    },
    copyToClipboard(text) {
      // 使用 Clipboard API 复制文本到剪贴板
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.alerts.push({
            message: '已复制到剪贴板！',
            timeout: 1000
          })
        })
        .catch((err) => {
          alert('复制失败: ' + err) // 提示用户复制失败
          this.alerts.push({
            message: '复制失败',
            timeout: 1000,
            type: 2 // 3秒后关闭
          })
        })
    }
  },
  mounted() {
    // 初始化当前时间并设置定时器每秒更新
    this.updateCurrentTime()
    this.interval = setInterval(this.updateCurrentTime, 1000)
  },
  beforeUnmount() {
    // 清除定时器
    clearInterval(this.interval)
  }
}
</script>

<style scoped>
.container {
  max-width: 600px;
}
</style>
