<template>
  <nav class="navbar navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid">
      <a class="navbar-brand" href="/TimeStamp">Tools</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              菜单
            </a>
            <ul class="dropdown-menu">
              <!-- <li>
                <a
                  class="dropdown-item"
                  :class="{ active: $route.path === '/TimeStamp' }"
                  href="/TimeStamp"
                  >时间戳</a
                >
              </li>

              <li>
                <a
                  class="dropdown-item"
                  :class="{ active: $route.path === '/StringLengthCalculator' }"
                  href="/StringLengthCalculator"
                  >字符串长度计算</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  :class="{ active: $route.path === '/JsonFormatter' }"
                  href="/JsonFormatter"
                  >Json格式化</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  :class="{ active: $route.path === '/UrlEncoderDecoder' }"
                  href="/UrlEncoderDecoder"
                  >URL编码</a
                >
              </li> -->

              <li v-for="(route, index) in filteredRoutes" :key="index">
                <router-link
                  class="dropdown-item"
                  :class="{ active: $route.path === route.path }"
                  :to="route.path"
                >
                  {{ route.name }}
                </router-link>
              </li>
            </ul>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" href="#">Link</a>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: 'NavMenu',
  props: {
    msg: String
  },
  computed: {
    filteredRoutes() {
      // 获取所有路由配置
      const allRoutes = this.$router.options.routes

      // 定义递归函数，处理嵌套路由的过滤
      const filterVisibleRoutes = (routes) => {
        return routes
          .filter((route) => {
            // 只过滤出有 name 且 visible 为 true 的路由
            return route.name && route.path !== '/' && route.visible !== false
          })
          .map((route) => {
            // 如果路由有子路由，则递归过滤子路由
            if (route.children) {
              route.children = filterVisibleRoutes(route.children)
            }
            return route
          })
      }

      // 过滤所有的路由
      return filterVisibleRoutes(allRoutes)
    }
  }
}
</script>
<style scoped></style>
