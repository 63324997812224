<template>
  <div>
    <div
      v-if="visible"
      class="alert alert-dismissible"
      role="alert"
      :class="{ 'alert-success': type === 1, 'alert-danger': type === 2 }"
    >
      <div>{{ message }}</div>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlertMessage',
  props: {
    // 提示框消息内容
    message: {
      type: String,
      required: true
    },
    // 自动关闭时间，默认为1000毫秒（1秒）
    timeout: {
      type: Number,
      default: 1000
    },
    type: {
      type: Number,
      default: 1 // 默认值为 1，显示 alert-primary 样式
    }
  },
  data() {
    return {
      visible: true // 控制提示框的显示与隐藏
    }
  },
  mounted() {
    // 使用 setTimeout 实现自动关闭
    setTimeout(() => {
      this.visible = false
    }, this.timeout)
  }
}
</script>

<style scoped>
.alert {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1050; /* 确保提示框在最上层 */
  width: auto;
  min-width: 200px; /* 可以根据需求调整宽度 */
}
</style>
